import React, { useEffect, useState } from 'react';
// import { connectSocket, disconnectSocket, sendMessage, subscribeToMessages } from './services/SocketService2';
//import SocketService1 from './services/SocketService1';
import io from 'socket.io-client';


function ChatComponent() {
    const [message, setMessage] = useState('');
    const [chat, setChat] = useState([]);

    ////////////////////////////////////////
    ////////////////////////////////////////

    let currentUser = "admin";
    let appPassword = "Dhaka222444";
    let tokent = "xxxxxxxx";
    let serverUrl = "wss://nrbd.org:3000";

    var options = {
        rememberUpgrade: true,
        transports: ['websocket'],
        secure: true,
        rejectUnauthorized: false,
        withCredentials: false,
        query: {
            user: currentUser,
            appPassword: appPassword,
            token: tokent
        },
    };

    var socket = io(serverUrl, options);



    console.log('Connecting socket...');
    // You can set up listeners here if needed
    socket.on('connect', () => {
        //const engine = socket.io.engine;
        console.log('Socket connected:', socket.id);

    });

    socket.on('disconnect', (reason) => {
        console.log('Socket disconnected:', reason);
    });

    // Error handling for connection issues
    socket.on('connect_error', (err) => {
        console.error('Connection Error:', err);
    });

    socket.on('receiveMessage', (message) => {
        console.log('Message received:', message);
        setChat((prevChat) => [...prevChat, message]);

    });

    const handleSend = () => {

        let msg2 = document.getElementById("msg1").value;
        if (socket && socket.connected) {
            socket.emit('sendMessage', msg2);
            console.log('Message sent:', msg2);
            setMessage('');
        } else {
            console.log('Socket is not connected. Cannot send message.');
        }
    };

    /////////////////////////////////////////////
    /////////////////////////////////////////////

    //const socketService1 = new SocketService1("wss://nrbd.org:3000");

    // useEffect(() => {

    //     socketService1.connect();
    //     socketService1.receiveMessage((msg8) => {
    //         console.log("function is triggered: " + msg8);

    //         //let newChatList = chat.push(msg8);
    //         //setChat(newChatList);
    //         setChat((prevChat) => [...prevChat, msg8]);
    //         //setChat((prevChat) => [...prevChat, msg8]);
    //     });

    //     // return () => {
    //     //     socketService1.disconnect();
    //     // };

    // }, []);

    // useEffect(() => {
    //     //Runs on every render
    //     console.log('Socket is connected....');
    // });

    // const handleSend = () => {

    //     let msg2 = document.getElementById("msg1").value;
    //     socketService1.sendMessage(msg2);
    //     setMessage('');

    // };

    return (
        <div>
            <input
                id="msg1"
                placeholder="Type a message..."
            />
            <button onClick={handleSend}>Send</button>
            <ul>
                {chat.map((message, index) => (
                    <p key={index}>{message}</p>
                ))}
            </ul>
        </div>
    );
}

export default ChatComponent;
